<template>
  <v-form v-model="formIsValid" ref="form" @submit.prevent="addAssistant">
    <v-row class="align-center" dense>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="formData.tin"
          label="NIF"
          :rules="[$data.$globalRules.required]"
          @keypress.enter="searchStudentByTin"
          @keyup="cleanTin"
        />
      </v-col>

      <v-col cols="12" sm="6" class="text-right">
        <v-btn
          :loading="searchingStudent"
          depressed
          @click="searchStudentByTin"
          :disabled="!formData.tin"
          small
          outlined
          color="success"
        >
          <v-icon class="mr-2"> mdi-magnify </v-icon>Buscar alumno
        </v-btn>
      </v-col>

      <v-col cols="12" v-if="searchStudentResponseText">
        <v-alert text :type="searchStudentResponseType" dense>
          {{ searchStudentResponseText }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-if="createStudentBy === 'form'">
      <v-col>
        <new-student-form :formData="formData" :clients="clients" :centers="centers" />
      </v-col>
    </v-row>

    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn color="primary" depressed type="submit" :disabled="disableAddStudentBtn">
          Añadir
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { cleanTin } from "@/utils/helpers";

export default {
  components: {
    newStudentForm: () =>
      import(
        "@/modules/cardioguard-trainer/components/views/trainer-session/AddAssistantDialogNewStudentForm"
      ),
  },
  props: {
    addingAssistant: { type: Boolean, default: false },
    centers: { type: Array, required: true },
    clients: { type: Array, required: true },
    dialog: { type: Boolean, default: false },
  },
  data: () => ({
    formIsValid: null,
    studentId: null,
    formData: {
      tin: null,
      name: null,
      surname: null,
      email: null,
      gender: null,
      clients: [],
      center: [],
      telephone: null,
    },
    searchingStudent: null,
    searchStudentResponseText: null,
    searchStudentResponseType: null,
    createStudentBy: null,
  }),
  mounted() {
    if (this.clients.length === 1) this.formData.clients = [this.clients[0].id];
    if (this.centers.length === 1) this.formData.centers = [this.centers[0].id];
  },
  methods: {
    addAssistant() {
      this.$refs.form.validate();
      if (this.formIsValid) {
        if (this.createStudentBy === "id") {
          this.$emit("addAssistant", this.studentId);
        } else {
          this.$emit("addNewStudentAssistant", this.formData);
        }
      }
    },
    async searchStudentByTin() {
      this.resetSearchStudentData();

      try {
        this.searchingStudent = true;
        const params = { sessionId: this.$route.params.id, tin: this.formData.tin };
        const response = await this.$store.state.currentService.searchStudentByTin(params);

        if (response.data.hasInscription) {
          this.handleSearchStudentError(response.data.studentId);
        } else if (response.data.studentId) {
          this.handleSearchStudentId(response.data.studentId);
        } else {
          this.handleSearchStudentForm();
        }
      } catch (e) {
        this.$notifyError(undefined, "No se ha podido buscar este alumno | " + e);
      } finally {
        this.searchingStudent = false;
      }
    },
    cleanTin() {
      this.formData.tin = cleanTin(this.formData.tin);
    },
    handleSearchStudentError(studentId) {
      this.searchStudentResponseText =
        "Este/a alumno/a ya está inscrito/a en el curso. Alumno ID: " + studentId;
      this.searchStudentResponseType = "error";
    },
    handleSearchStudentId(studentId) {
      this.studentId = studentId;
      this.searchStudentResponseText =
        "Alumno/a existente en base de datos, puedes añadirlo sin especificar datos.";
      this.searchStudentResponseType = "success";
      this.createStudentBy = "id";
    },
    handleSearchStudentForm() {
      this.searchStudentResponseText =
        "Este/a alumno/a no existe en la base de datos, puedes crearlo/a a continuación.";
      this.searchStudentResponseType = "info";
      this.createStudentBy = "form";
    },
    resetSearchStudentData() {
      this.searchStudentResponseText = null;
      this.searchStudentResponseType = null;
      this.createStudentBy = null;
    },
  },
  computed: {
    disableAddStudentBtn() {
      return !this.createStudentBy;
    },
    dialogComp: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("handleDialog", value);
      },
    },
  },
};
</script>

<style scoped></style>
